<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro01 from '@/components/shared/relatorio/filtros/Filtro01.vue'

export default {
  extends: Filtro01,

  data() {
    return {
      modulo: 'consignataria',
    }
  },

  methods: {
    carregarConsignataria() {
      this.consignatariasSelecionadas.push(
        JSON.parse(localStorage.getItem('consignatariaSelecionada')),
      )
      this.adicionarConsignataria()
      this.exibirListaConsignataria = false
    },
  },
}
</script>
